import React from 'react'
import { Helmet } from 'react-helmet';


const Workshop = () => {
    return (

        <>

            <div className='overflow-hidden'>

                <Helmet>
                    <title>Workshop: Direction, Cinematography, Editing, & VFX Skills</title>
                    <meta
                        name="description"
                        content="Enhance your skills in direction, cinematography, editing, and VFX with our expert-led workshops. Hands-on training for aspiring professionals. Join now!"
                    />
                    <meta
                        name="keywords"
                        content="VFX training In india | VFX Training Courses | Jobs in VFX | Virtual Production institute | Virtual production training | Learn Direction courses | Acting institute In India | Learn Acting Courses | Best Direction Training Institute | Photography courses in india | Learn photography courses | Photography Training institute in India | Acting Training Institute in India | Cinematography courses | cinematography training institute in India | learn Editing Courses | Editing Training Institute in India | Visual effects courses | Visual effects training institute in India | Learn Visual effects Courses | Best VFX institute in India | Learn VFX Courses | Visual effects institute in India"
                    />
                    <meta name="author" content="Cinema Factory Academy" />
                    <meta charSet="utf-8" />
                    {/* Add other meta tags here if needed */}
                </Helmet>

                <div className='flex justify-center h-screen items-center'>

                    <div className='w-full md:w-[80%] mx-auto'>

                        <h3 className='text-center font-semibold font-[poppins]'>Stay tuned! For Next Webinar...</h3>

                        {/* <Link to='https://webinar.photography.cinemafactory.co.in/' target='_blank'>
                            <div className='flex justify-center'>
                                <img src={photo} className='w-[80%] object-cover' alt='none' />
                            </div>
                        </Link> */}

                    </div>


                </div>

            </div>

        </>

    )
}

export default Workshop