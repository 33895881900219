import React from 'react'

const PaymentPage = () => {
    return (

        <>

            <section>

                <div className='h-screen w-ull font-[poppins] flex justify-center items-center  mx-auto '>

                    <div className='flex flex-col gap-y-10 justify-center items-center'>

                      

                            <div>
                                <h3>Thanks For Sending the Message !</h3>
                            </div>

                            {/* <div>
                                <button className='px-8 py-2 rounded-md bg-[#ff0000] text-[14px] text-white font-semibold'>Payment Now</button>
                            </div> */}
                       

                    </div>


                </div>
            </section>

        </>

    )
}

export default PaymentPage
