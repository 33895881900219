
// cj_kumar


// ramesh_kumar
import rameshbanner from '../../images/mentors/ramesh/ramesh_banner.png'
import rameshmobile from '../../images/mentors/ramesh/ramesh_mobile.png'
import rameshvideo from '../../images/mentors/ramesh/manoj.mp4'
import rameshsuccess from '../../images/mentors/ramesh/ramesh.jpg'

import rameshOne from '../../images/poster/ramesh/mv1.png'
import rameshTwo from '../../images/poster/ramesh/mv2.png'
import rameshThree from '../../images/poster/ramesh/mv3.png'
import rameshFour from '../../images/poster/ramesh/mv4.png'
import rameshFive from '../../images/poster/ramesh/mv5.png'


// ramesh_kumar
// import rajabanner from '../../images/mentors/raja/raja_website.png'
// import rajamobile from '../../images/mentors/raja/raja_phone.png'
// import rajavideo from '../../images/mentors/raja/manoj.mp4'
// import rajasuccess from '../../images/mentors/raja/raja.png'

// import rajaOne from '../../images/poster/raja/1.png'
// import rajaTwo from '../../images/poster/raja/2.png'
// import rajaThree from '../../images/poster/raja/3.png'
// import rajaFour from '../../images/poster/raja/4.png'
// import rajaFive from '../../images/poster/raja/5.png'
// import rajaSix from '../../images/poster/raja/6.png'




export const users = [

  // Ramesh kumar

  {
    banner: rameshbanner,
    bannerOne: rameshmobile,
    title : "Master the art of cinema and advertising from the visionary behind Oomai Vizhigal",

    cjvideo : rameshvideo ,

    contentOne : 'Revolutionized Tamil cinema with the trend-setting visuals of Oomai Vizhigal',
    contentTwo : 'Popularized the cinemascope format in filmmaking.',
    contentThree : 'Directed over 800 commercials, setting new trends in advertising.',
    contentFour : ' Pioneered high-quality film education in leading film institutes',
    contentFive :  'Notable films : Captain Prabhakaran, Uzhavan Magan, Thennavan, Thaai Naadu, Independence Day.',
    contentSix : 'Popularized the cinemascope format in filmmaking.',
    
    successOne : 'Innovative contributions to both cinema and advertising.',
    successTwo : '  Visual revolution achieved with Oomai Vizhigal.',
    successThree : 'Established benchmarks in advertising with over 800 commercials.', 
    successFour : 'Shaped the next generation of filmmakers through film education.' ,
    successFive : 'Established benchmarks in advertising with over 800 commercials.',

    successImg :  rameshsuccess ,

    posterOne : rameshOne,
    posterTwo : rameshTwo,
    posterThree : rameshThree, 
    posterFour : rameshFour, 
    posterFive : rameshFive,
    posterSix : rameshThree,

    
    username: "ramesh_kumar",
    
    
  },



  // Raja 

  

 



];